import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ordersTableData from "layouts/ratehawkOrders/data/ordersTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActivity } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { fetchAllBookings } from "redux/Actions/Bookings";
import { fetchAllOrders } from "redux/Actions/Orders";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import instance from "../../helpers/axiosInstance";
import Loader from "examples/Loader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DownloadingRounded, Payment, RestartAltRounded } from "@mui/icons-material";

function EsimOrders() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const crm_uuid = queryParams?.get("crm_uuid");

  const [transactions, setTransactions] = useState(null);
  const [transactionsCount, setTransactionsCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    status: 0,
    region_type: 0,
    type: 0,
    first_name: null,
    email: null,
    last_name: null,
    from: null,
    to: null,
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    setFilter((prev) => ({ ...prev, crm_uuid }));
  }, [crm_uuid]);

  useEffect(() => {
    if (!transactions) {
      fetchAiraloOrders();
    }
  }, [transactions]);

  const applyFilters = () => {
    setPage(0);
    fetchAiraloOrders();
  };

  const resetFilter = () => {
    setFilter((prev) => ({
      status: 0,
      region_type: 0,
      type: 0,
      first_name: null,
      email: null,
      last_name: null,
      from: null,
      to: null,
    }));

    setTransactions(null);
    setTransactionsCount(null);
    setPage(0);
  };

  const fetchAiraloOrders = async (uuid) => {
    try {
      setLoading(true);

      const { data } = await instance.post("/airalo-orders/fetch-all-crm", {
        ...filter,
        page: page + 1,
        pageSize,
      });

      setTransactions(data?.airalo?.rows);
      setTransactionsCount(data?.airalo?.count);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} component={Paper}>
        <Box
          my={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            placeholder="First Name"
            value={filter?.first_name}
            onChange={(e) => setFilter((prev) => ({ ...prev, first_name: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="Last Name"
            value={filter?.last_name}
            onChange={(e) => setFilter((prev) => ({ ...prev, last_name: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="E-mail"
            value={filter?.email}
            onChange={(e) => setFilter((prev) => ({ ...prev, email: e.target.value }))}
          ></TextField>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.from}
              name="From"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, from: moment(date).format("YYYY-MM-DD") }));
              }}
              label="From"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.to}
              name="To"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, to: moment(date).format("YYYY-MM-DD") }));
              }}
              label="To"
            />
          </LocalizationProvider>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.status}
              label="Status"
              onChange={(e) => setFilter((prev) => ({ ...prev, status: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={"COMPLETED"}>Completed</MenuItem>
              <MenuItem value={"FAILED"}>Failed</MenuItem>
              <MenuItem value={"PENDING"}>Pending</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Region Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.region_type}
              label="Region Type"
              onChange={(e) => setFilter((prev) => ({ ...prev, region_type: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={"LOCAL"}>LOCAL</MenuItem>
              <MenuItem value={"GLOBAL"}>GLOBAL</MenuItem>
              <MenuItem value={"REGIONAL"}>REGIONAL</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.type}
              label="Type"
              onChange={(e) => setFilter((prev) => ({ ...prev, type: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={"sim"}>SIM</MenuItem>
              <MenuItem value={"topup"}>Top-Up</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* <Box sx={{ border: "solid", display: "block" }}> */}
        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Button
            onClick={() => {
              applyFilters();
            }}
            sx={{ color: "white !important" }}
            variant="contained"
          >
            Apply Filters
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<RestartAltRounded />}
            sx={{ color: "white !important" }}
            onClick={() => {
              resetFilter();
            }}
          >
            Reset filters
          </Button>
          {/* <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<DownloadingRounded />}
                sx={{ color: "white !important" }}
                onClick={() => {
                  if (transactions != null && transactions?.length > 0) {
                    return exportToCSV(transactions, `${moment().format("YYYY-MM-DD")} report`);
                  } else {
                    return alert("Nothing to report");
                  }
                }}
              >
                Export to excel
              </Button> */}
        </Box>

        <TableContainer>
          <Table sx={{ minWidth: 650, width: "100%" }} aria-label="Hotel Orders">
            <TableHead sx={{ background: "#F1F2F4", width: "100%", display: "table-header-group" }}>
              <TableRow sx={{ width: "100%" }}>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Full name</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Email</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>User</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Type</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Package ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Esim type</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Description</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Quantity</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Plan type</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Phone type</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Payment Platform</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Payment ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Amount</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>ICCID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Title</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Region type</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Slug</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Operator ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Date</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {transactions?.length > 0 ? (
              <TableBody>
                {transactions?.map((transaction, index) => {
                  let payment_platform;

                  if (transaction?.stripe_uuid) {
                    payment_platform = "Stripe";
                  } else if (transaction?.pb_order_no) {
                    payment_platform = "PayBy";
                  } else if (transaction?.wallet_transaction_id) {
                    payment_platform = "Wallet";
                  }
                  return (
                    <TableRow key={index}>
                      <TableCell sx={tableCellStyle}>{transaction?.airalo_order_id}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {transaction?.user
                          ? `${transaction?.user?.first_name} ${transaction?.user?.last_name}`
                          : "N/A"}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.email}</TableCell>
                      <TableCell>
                        {transaction?.user?.username ? (
                          <Link
                            to={"/users/" + transaction?.user?.username}
                            target={"_blank"}
                            style={{ fontSize: "14px", color: "rgb(62, 137, 214)" }}
                          >
                            {transaction?.user?.username}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.type}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.package_id}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.esim_type}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.description}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.quantity}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.plan_type}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.phone_type}</TableCell>
                      <TableCell sx={tableCellStyle}>{payment_platform}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {transaction?.stripe_uuid ||
                          transaction?.pb_merch_order_no ||
                          transaction?.wallet_transaction_id}
                      </TableCell>
                      <TableCell
                        sx={tableCellStyle}
                      >{`${transaction?.amount} ${transaction?.currency}`}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.iccid}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.title}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.region_type}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.slug}</TableCell>
                      <TableCell sx={tableCellStyle}>{transaction?.operator_id}</TableCell>

                      <TableCell sx={tableCellStyle}>
                        {moment(transaction?.createdAt).format("ll")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          sx={{ display: "flex" }}
          count={transactionsCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
            setTransactions(null);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(e.target.value);
            setPage(0);
            setTransactions(null);
          }}
        />
        {/* </Box> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default EsimOrders;

const tableCellStyle = {
  fontSize: "14px",
};
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const showAmountWithCommission = (amount, commission) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  return roundCommision(newAmount).toLocaleString();
};

export const roundCommision = (number) => {
  return Math.ceil(number);
};

export const addCommision = (amount, commission = 6, ciel = true) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  if (ciel) {
    return roundCommision(newAmount);
  }
  return newAmount;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "60vw",

  maxHeight: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
