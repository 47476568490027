import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React from "react";

function AddPointsForm({ handleAddPointTransaction }) {
  const [localTransactions, setLocalTransaction] = React.useState({
    transaction_type: "CREDIT",
    transaction_name: null,
    amount: null,
    description: null,
    expires_on: null,
    one_point_to_aed: 0.01,
  });

  const handleChangeTransaction = (name, value) => {
    setLocalTransaction((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    handleAddPointTransaction(localTransactions);
  };

  return (
    <>
      <Box py={2}>
        <Typography variant="h6" textAlign={"center"}>
          1 Point = 0.01 AED
        </Typography>
      </Box>
      <Grid container spacing={2} component={"form"} onSubmit={handleSubmitForm}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Transaction type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={localTransactions?.transaction_type}
              label="Status"
              onChange={(e) =>
                setLocalTransaction((prev) => ({ ...prev, transaction_type: e.target.value }))
              }
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={"DEBIT"}>Debit</MenuItem>
              <MenuItem value={"CREDIT"}>Credit</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Transaction name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={localTransactions?.transaction_name}
              label="Status"
              onChange={(e) =>
                setLocalTransaction((prev) => ({ ...prev, transaction_name: e.target.value }))
              }
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={"WALLET_TOP_UP"}>
                {getTransactionNameWithConstant("WALLET_TOP_UP", "CREDIT")}
              </MenuItem>
              <MenuItem value={"HOTEL_BOOKING"}>
                {getTransactionNameWithConstant("HOTEL_BOOKING", "CREDIT")}
              </MenuItem>
              <MenuItem value={"POST"}>{getTransactionNameWithConstant("POST", "CREDIT")}</MenuItem>
              <MenuItem value={"SIGN_UP"}>
                {getTransactionNameWithConstant("SIGN_UP", "CREDIT")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Amount"
            variant="outlined"
            value={localTransactions?.original_amount}
            required
            type="number"
            onChange={(e) => {
              return handleChangeTransaction("amount", e.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end">PTS</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={localTransactions?.description}
            required
            type="text"
            onChange={(e) => {
              return handleChangeTransaction("description", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Expiry Date"
              onChange={(e) =>
                setLocalTransaction((prev) => ({ ...prev, expires_on: moment(e).format() }))
              }
              views={["year", "month", "day", "hours", "minutes"]}
              inputFormat="YYYY-MM-DD HH:mm:ss"
              renderInput={(params) => <TextField fullWidth required {...params} />}
              value={localTransactions?.expires_on}
            />
          </LocalizationProvider>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            py: 2,
          }}
        >
          <Button type="submit" variant="contained" sx={{ padding: "10px 20px", color: "#fff" }}>
            Submit
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default AddPointsForm;

const getTransactionNameWithConstant = (name, type) => {
  switch (name) {
    case "WALLET_TOP_UP":
      return "Wallet Top up";
    case "HOTEL_BOOKING":
      return "Hotel Booking";
    case "HOTEL_BOOKING_CANCEL":
      return "Hotel Booking Cancellation";
    case "VISA":
      return "Visa payment";
    case "ESIM":
      return "E-sim payment";
    case "POST":
      return "Post";
    case "SIGN_UP":
      return "User Sign up";
    default:
      return type == "CREDIT" ? "Wallet Credit" : "Wallet Purchase";
  }
};
