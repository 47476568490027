import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import moment from "moment";
import React, { useState } from "react";

function New() {
  const [promo, setPromo] = useState();
  const [localPromo, setLocalPromo] = useState({
    is_active: true,
    usage_count: 0,
    discount_type: "percentage",
    start_date: moment(),
    end_date: moment().add("1", "days"),
  });
  const [loading, setLoading] = useState(false);

  const handleChangePromo = (name, value) => {
    setLocalPromo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSavePromo = async (e) => {
    e.preventDefault();

    if (moment(localPromo?.end_date).isSameOrBefore(moment(localPromo?.start_date))) {
      return alert("End date must be greater than start date");
    }

    if (localPromo?.discount_type === "amount" && Number(localPromo?.amount) <= 15)
      return alert("Amount must be greater than 15 AED");

    try {
      const { data } = await axiosInstance.post("/promo-code", localPromo);

      if (data?.status == 200) {
        return alert("Promo code created successfully");
      }
    } catch (error) {
      return alert("Something went wrong, please try again");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={3}>
        <Grid container spacing={3} pt={6} component="form" onSubmit={handleSavePromo}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Code"
              variant="outlined"
              value={localPromo?.code}
              required
              onChange={(e) => {
                return handleChangePromo("code", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Usage limit"
              variant="outlined"
              value={localPromo?.usage_limit}
              required
              type="number"
              onChange={(e) => {
                return handleChangePromo("usage_limit", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Usage Count"
              variant="outlined"
              value={localPromo?.usage_count}
              required
              type="number"
              onChange={(e) => {
                return handleChangePromo("usage_count", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              value={localPromo?.description}
              required
              onChange={(e) => {
                return handleChangePromo("description", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Description AR"
              variant="outlined"
              dir="rtl"
              value={localPromo?.description_ar}
              required
              onChange={(e) => {
                return handleChangePromo("description_ar", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Start Date"
                onChange={(e) =>
                  setLocalPromo((prev) => ({ ...prev, start_date: moment(e).format() }))
                }
                disablePast
                views={["year", "month", "day", "hours", "minutes"]}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                renderInput={(params) => <TextField fullWidth required {...params} />}
                value={localPromo?.start_date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="End Date"
                onChange={(e) =>
                  setLocalPromo((prev) => ({ ...prev, end_date: moment(e).format() }))
                }
                disablePast
                views={["year", "month", "day", "hours", "minutes"]}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                renderInput={(params) => <TextField fullWidth required {...params} />}
                value={localPromo?.end_date}
                // minDate={localPromo?.start_date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Discount Amount"
              variant="outlined"
              value={localPromo?.discount_amount}
              required
              type="number"
              onChange={(e) => {
                handleChangePromo("discount_amount", e.target.value);
              }}
              error={
                localPromo?.discount_type === "amount" && Number(localPromo?.discount_amount) <= 15
              }
              helperText={
                localPromo?.discount_type === "amount" && Number(localPromo?.discount_amount) <= 15
                  ? "Amount must be greater than 15 AED"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localPromo?.discount_type}
                label="Status"
                onChange={(e) =>
                  setLocalPromo((prev) => ({ ...prev, discount_type: e.target.value }))
                }
                size="medium"
                sx={{ height: "44.13px", minWidth: "160px" }}
                variant="outlined"
              >
                <MenuItem value={"percentage"}>% (percentage)</MenuItem>
                <MenuItem value={"amount"}>AED (Amount)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Checkbox
                checked={localPromo?.is_active}
                onChange={() => handleChangePromo("is_active", !localPromo?.is_active)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>Is Active</span>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="outlined"
              sx={{ fontSize: "24px", fontWeight: "bold", color: "black !important" }}
              // onClick={handleSavePromo}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default New;
