/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import usersTableData from "layouts/users/data/usersTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCountries } from "redux/Actions/Countries";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import { Link, useNavigate } from "react-router-dom";
import { fetchUsers } from "redux/Actions/User";
import axiosInstance from "helpers/axiosInstance";
import moment from "moment";
import { Delete, Edit, RestartAltRounded } from "@mui/icons-material";
import { verifyUser } from "redux/Actions/User";
import { deleteUser } from "redux/Actions/User";
import Loader from "examples/Loader";

function Country() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState(null);
  const [usersCount, setUsersCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    first_name: null,
    last_name: null,
    email: null,
    username: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!users) {
      fetchUsers();
    }
  }, [users]);

  const applyFilters = () => {
    setPage(0);
    fetchUsers();
  };

  const resetFilter = () => {
    setFilter((prev) => ({
      first_name: null,
      last_name: null,
      email: null,
      username: null,
    }));

    setUsers(null);
    setUsersCount(null);
    setPage(0);
  };

  const fetchUsers = async (uuid) => {
    try {
      setLoading(true);

      const { data } = await axiosInstance.post("/users/fetch-crm", {
        ...filter,
        page: page + 1,
        pageSize,
      });

      setUsers(data?.users?.rows);
      setUsersCount(data?.users?.count);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const handleVerifyUser = async (uuid, profile_id, is_verified) => {
    const confirmed = window.confirm(
      is_verified
        ? "Are you sure you want un verify this user ?"
        : "Are you sure you want to verify this user ?"
    );
    if (confirmed) {
      dispatch(verifyUser({ profile_id }, is_verified));
      const newMapArray = users.map((user) => {
        if (user?.uuid === uuid) {
          user.profile.is_verified = !is_verified;
          return user;
        }
        return user;
      });

      await Promise.all(newMapArray);

      setUsers(newMapArray);
    }
  };

  const handleDeleteUser = async (uuid) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this user?");
    if (confirmed) {
      dispatch(deleteUser({ uuid }));

      const newMapArray = users.map((user) => {
        if (user?.uuid === uuid) return undefined;
        return user;
      });

      await Promise.all(newMapArray);

      setUsers(newMapArray.filter((user) => user));
    }
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <Grid container py={5}>
      <Grid item xs={12}>
        <Box>
          <Box
            my={4}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <TextField
              placeholder="First Name"
              value={filter?.first_name}
              onChange={(e) => setFilter((prev) => ({ ...prev, first_name: e.target.value }))}
            ></TextField>
            <TextField
              placeholder="Last Name"
              value={filter?.last_name}
              onChange={(e) => setFilter((prev) => ({ ...prev, last_name: e.target.value }))}
            ></TextField>
            <TextField
              placeholder="Email"
              value={filter?.email}
              onChange={(e) => setFilter((prev) => ({ ...prev, email: e.target.value }))}
            ></TextField>
            <TextField
              placeholder="Username"
              value={filter?.username}
              onChange={(e) => setFilter((prev) => ({ ...prev, username: e.target.value }))}
            ></TextField>
          </Box>

          <Box mb={4} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
            <Button
              onClick={() => {
                applyFilters();
              }}
              sx={{ color: "white !important" }}
              variant="contained"
            >
              Apply Filters
            </Button>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<RestartAltRounded />}
              sx={{ color: "white !important" }}
              onClick={() => {
                resetFilter();
              }}
            >
              Reset filters
            </Button>
            {/* <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<DownloadingRounded />}
              sx={{ color: "white !important" }}
              onClick={() => {
                if (transactions != null && transactions?.length > 0) {
                  return exportToCSV(transactions, `${moment().format("YYYY-MM-DD")} report`);
                } else {
                  return alert("Nothing to report");
                }
              }}
            >
              Export to excel
            </Button> */}
          </Box>

          {/* <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              Users Table
            </MDTypography>
            <Box sx={{ width: "30%", display: "flex", justifyContent: "space-between", gap: 2 }}>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  sx: {
                    "& input": {
                      background: "#FFF",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
              <Button
                sx={{ background: "#000", ":hover": { background: "#212121" } }}
                onClick={() => navigate("/users/new")}
              >
                New
              </Button>
            </Box>
          </MDBox> */}
          <TableContainer>
            <Table sx={{ minWidth: 650, width: "100%" }} aria-label="Hotel Orders">
              <TableHead
                sx={{ background: "#F1F2F4", width: "100%", display: "table-header-group" }}
              >
                <TableRow sx={{ width: "100%" }}>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>ID</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Name</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Username</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Email</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Verified</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Phone Number</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Age</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Last Seen</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Edit</TableSortLabel>
                  </TableCell>
                  <TableCell sx={tableCellStyle}>
                    <TableSortLabel>Delete</TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              {users?.length > 0 ? (
                <TableBody>
                  {users?.map((user, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell sx={tableCellStyle}>{index + 1}</TableCell>
                        <TableCell
                          sx={tableCellStyle}
                        >{`${user?.first_name} ${user?.last_name}`}</TableCell>
                        <TableCell>{user?.username}</TableCell>
                        <TableCell sx={tableCellStyle}>{user?.email}</TableCell>
                        <TableCell sx={tableCellStyle}>
                          <Switch
                            checked={user?.profile?.is_verified}
                            onChange={() =>
                              handleVerifyUser(
                                user?.uuid,
                                user?.profile?.profile_id,
                                user?.profile?.is_verified
                              )
                            }
                          />
                        </TableCell>
                        <TableCell sx={tableCellStyle}>{user?.phone_number || "N/A"}</TableCell>
                        <TableCell sx={tableCellStyle}>
                          {user?.date_of_birth ? getAge(user?.date_of_birth) : "N/A"}
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          {user?.last_login ? moment(user?.last_login).fromNow() : "N/A"}
                        </TableCell>

                        <TableCell sx={tableCellStyle}>
                          <IconButton onClick={() => navigate(`/users/${user?.username}`)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell sx={tableCellStyle}>
                          {/* <IconButton onClick={() => navigate(`/users/${user?.username}`)}> */}
                          <Delete
                            onClick={() => handleDeleteUser(user?.uuid)}
                            fontSize="medium"
                            color="error"
                            fontWeight="medium"
                            sx={{
                              cursor: "pointer",
                            }}
                          />
                          {/* </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                ""
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            sx={{ display: "flex" }}
            count={usersCount}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(e, page) => {
              setPage(page);
              setUsers(null);
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(e.target.value);
              setPage(0);
              setUsers(null);
            }}
          />
          {/* {Rows?.length > 0 && (
            <MDBox pt={3}>
              <DataTable
                table={{ columns: Columns, rows: Rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          )} */}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Country;

const tableCellStyle = {
  fontSize: "14px",
};

function getAge(dateOfBirth) {
  const today = moment();
  const birthDate = moment(dateOfBirth, "YYYY-MM-DD");
  const age = today.diff(birthDate, "years");
  return age;
}
