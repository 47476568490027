import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

function AddWalletForm({ handleAddWalletTransaction }) {
  const [localTransactions, setLocalTransaction] = React.useState({
    transaction_type: "CREDIT",
    transaction_name: null,
    amount: null,
    description: null,
    currency: "AED",
    original_amount: null,
    original_currency: "AED",
  });

  const handleChangeTransaction = (name, value) => {
    setLocalTransaction((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    let locWallTrans = {
      ...localTransactions,
      amount: Number(localTransactions.original_amount),
      currency: "AED",
      original_currency: "AED",
    };
    handleAddWalletTransaction(locWallTrans);
  };

  return (
    <Grid container spacing={2} component={"form"} onSubmit={handleSubmitForm}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Transaction type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={localTransactions?.transaction_type}
            label="Status"
            onChange={(e) =>
              setLocalTransaction((prev) => ({ ...prev, transaction_type: e.target.value }))
            }
            size="medium"
            sx={{ height: "44.13px", minWidth: "160px" }}
            variant="outlined"
          >
            <MenuItem value={"DEBIT"}>Debit</MenuItem>
            <MenuItem value={"CREDIT"}>Credit</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Transaction name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={localTransactions?.transaction_name}
            label="Status"
            onChange={(e) =>
              setLocalTransaction((prev) => ({ ...prev, transaction_name: e.target.value }))
            }
            size="medium"
            sx={{ height: "44.13px", minWidth: "160px" }}
            variant="outlined"
          >
            <MenuItem value={"WALLET_TOP_UP"}>
              {getTransactionNameWithConstant("WALLET_TOP_UP", "CREDIT")}
            </MenuItem>
            <MenuItem value={"HOTEL_BOOKING"}>
              {getTransactionNameWithConstant("HOTEL_BOOKING", "DEBIT")}
            </MenuItem>
            <MenuItem value={"HOTEL_BOOKING_CANCEL"}>
              {getTransactionNameWithConstant("HOTEL_BOOKING_CANCEL", "CREDIT")}
            </MenuItem>
            <MenuItem value={"VISA"}>{getTransactionNameWithConstant("VISA", "DEBIT")}</MenuItem>
            <MenuItem value={"ESIM"}>{getTransactionNameWithConstant("ESIM", "DEBIT")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Amount"
          variant="outlined"
          value={localTransactions?.original_amount}
          required
          type="number"
          onChange={(e) => {
            return handleChangeTransaction("original_amount", e.target.value);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="end">AED</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          value={localTransactions?.description}
          required
          type="text"
          onChange={(e) => {
            return handleChangeTransaction("description", e.target.value);
          }}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          py: 2,
        }}
      >
        <Button type="submit" variant="contained" sx={{ padding: "10px 20px", color: "#fff" }}>
          Submit
        </Button>
      </Box>
    </Grid>
  );
}

export default AddWalletForm;

const getTransactionNameWithConstant = (name, type) => {
  switch (name) {
    case "WALLET_TOP_UP":
      return "Wallet Top up";
    case "HOTEL_BOOKING":
      return "Hotel Booking";
    case "HOTEL_BOOKING_CANCEL":
      return "Hotel Booking Cancellation";
    case "VISA":
      return "Visa payment";
    case "ESIM":
      return "E-sim payment";
    default:
      return type == "CREDIT" ? "Wallet Credit" : "Wallet Purchase";
  }
};
