/* eslint-disable no-nested-ternary */
import { Add, Close, ExpandMore, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ConfirmationDialog from "components/Confirmation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { fetchAllCountries } from "redux/Actions/Countries";
import {
  updateUserProfilePicture,
  updateUserProfileCover,
  updateUserProfile,
  fetchUserProfile,
} from "redux/Actions/User";

import instance from "../../helpers/axiosInstance";
import AddWalletForm from "./AddWalletForm";
import AddPointsForm from "./AddPointsForm";

function Edit() {
  const { username } = useParams();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state?.profile);
  const countries = useSelector((state) => state?.countries);
  const [Profile, setProfile] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [walletTransactions, setWalletTransactions] = useState(null);
  const [points, setPoints] = useState(null);
  const [pointsTransactions, setPointsTransactions] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [options, setOptions] = useState(null);
  const [dob, setDob] = useState(null);

  const [Cover, setCover] = useState([]);
  const [CoverFile, setCoverFile] = useState(null);

  const [ProPic, setProPic] = useState([]);
  const [ProPicFile, setProPicFile] = useState(null);

  const [openWallet, setOpenWallet] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile({ username }));
    dispatch(fetchAllCountries());

    return () => {
      dispatch(fetchUserProfile({ username, unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (!profile) return;
    setProfile({
      uuid: profile?.uuid,
      username: profile?.username,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      email: profile?.email,
      bio: profile?.profile?.bio,
      phone_number: profile?.phone_number,
      cover_picture: profile?.profile?.cover_picture,
      profile_picture: profile?.profile?.profile_picture,
      country_id: profile?.country_id,
      facebook_link: profile?.profile?.facebook_link,
      instagram_link: profile?.profile?.instagram_link,
      linktree_link: profile?.profile?.linktree_link,
      snapchat_link: profile?.profile?.snapchat_link,
      tiktok_link: profile?.profile?.tiktok_link,
      twitter_link: profile?.profile?.twitter_link,
    });

    setUserDetails({
      name: `${profile?.first_name} ${profile?.last_name}`,
      UUID: profile?.uuid,
      email: profile?.email,
      username: profile?.username,
      "Phone number": profile?.phone_number,
      "Last seen": moment(profile?.last_login).format("lll"),
      "date of birth": profile?.date_of_birth,
    });

    setUserSettings({
      language: profile?.language ? "English" : "Arabic",
      currency: profile?.currency,
      OS: profile?.operating_system || "N/A",
      "Sign up from": profile?.from_mobile ? "Mobile" : "Web",
      "Mobile version": profile?.version || "N/A",
      store: profile?.store || "N/A",
      Theme: profile?.is_dark_mode ? "Dark" : "Light",
    });

    setUserLocation({
      Longitude: profile?.longitude || "N/A",
      Latitude: profile?.latitude || "N/A",
      Location: profile?.location || "N/A",
      "Country name": profile?.country_name || "N/A",
      "Country code": profile?.country_code || "N/A",
      Country: profile?.country || "N/A",
    });

    getWalletTotal();
    getPointsTotal();
    getPointsTransactions();
    getWalletTransactions();
    // setDob(moment(profile?.date_of_birth));
  }, [profile]);

  const getWalletTotal = async () => {
    try {
      const { data } = await instance.post(`/wallet-transactions/fetch-crm-total`, {
        crm_uuid: profile?.uuid,
        currency: "AED",
      });

      setWallet(data?.wallet_transactions);
    } catch (error) {
      alert(error?.message);
    }
  };

  const getWalletTransactions = async () => {
    try {
      const { data } = await instance.post(`/wallet-transactions/fetch-crm`, {
        page: 1,
        pageSize: 5,
        crm_uuid: profile?.uuid,
      });

      setWalletTransactions(data?.wallet_transactions?.rows);
    } catch (error) {
      alert(error?.message);
    }
  };

  const getPointsTotal = async () => {
    try {
      const { data } = await instance.post(`/points/fetch-crm-total`, {
        crm_uuid: profile?.uuid,
      });

      setPoints(data?.points);
    } catch (error) {
      alert(error?.message);
    }
  };

  const getPointsTransactions = async () => {
    try {
      const { data } = await instance.post(`/points/fetch-crm`, {
        page: 1,
        pageSize: 5,
        crm_uuid: profile?.uuid,
      });

      setPointsTransactions(data?.points?.rows);
    } catch (error) {
      alert(error?.message);
    }
  };

  useEffect(() => {
    if (countries?.length > 0) {
      setOptions(countries?.map((val) => ({ value: val?.country_id, label: val?.name_en })));
    }
  }, [countries]);

  const handleAddWalletTransaction = (transaction) => {
    try {
      const { data } = instance.post(`/wallet-transactions/create-admin`, {
        ...transaction,
        crm_uuid: profile?.uuid,
      });

      alert("Added wallet transaction successfully");
    } catch (error) {
      alert(error?.message);
    }
  };

  const handleAddPointTransaction = (transaction) => {
    try {
      const { data } = instance.post(`/points/create-admin`, {
        ...transaction,
        crm_uuid: profile?.uuid,
      });

      alert("Added points transaction successfully");
    } catch (error) {
      alert(error?.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5} sx={{ borderRight: "solid 1px #212121" }}>
          <Box sx={{ width: "100%", paddingTop: "20px" }}>
            {Profile?.profile_picture ? (
              <Box
                sx={{
                  position: "relative",
                  background: `url("${
                    Profile?.profile_picture ||
                    "https://jadwelny.oss-me-east-1.aliyuncs.com/background/no_image_en.jpg"
                  }") center center / cover no-repeat`,
                  height: "150px",
                  width: "70%",
                  maxWidth: "150px",
                  borderRadius: "6px",
                }}
              ></Box>
            ) : (
              <Box
                sx={{
                  position: "relative",
                  background: `url("${"https://jadwelny.oss-me-east-1.aliyuncs.com/background/no_image_en.jpg"}") center center / cover no-repeat`,
                  height: "150px",
                  maxWidth: "150px",
                  width: "70%",
                  borderRadius: "6px",
                }}
              ></Box>
            )}
          </Box>
          <Box pt={4} pr={2}>
            {userDetails && (
              <>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    User Details
                  </AccordionSummary>
                  <AccordionDetails>
                    <table style={{ borderSpacing: "10px" }}>
                      {Object?.keys(userDetails)?.length > 0 &&
                        Object?.keys(userDetails)?.map((key) => (
                          <tr style={{ fontSize: "14px" }}>
                            <td style={{ textTransform: "capitalize" }}>{key}</td>
                            <td style={{ color: "#3b67bf" }}>{userDetails[key]}</td>
                          </tr>
                        ))}
                    </table>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {userSettings && (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    User Settings
                  </AccordionSummary>
                  <AccordionDetails>
                    <table style={{ borderSpacing: "10px" }}>
                      {Object?.keys(userSettings)?.length > 0 &&
                        Object?.keys(userSettings)?.map((key) => (
                          <tr style={{ fontSize: "14px" }}>
                            <td style={{ textTransform: "capitalize" }}>{key}</td>
                            <td style={{ color: "#3b67bf" }}>{userSettings[key]}</td>
                          </tr>
                        ))}
                    </table>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {userLocation && (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    User Location
                  </AccordionSummary>
                  <AccordionDetails>
                    <table style={{ borderSpacing: "10px" }}>
                      {Object?.keys(userLocation)?.length > 0 &&
                        Object?.keys(userLocation)?.map((key) => (
                          <tr style={{ fontSize: "14px" }}>
                            <td style={{ textTransform: "capitalize" }}>{key}</td>
                            <td style={{ color: "#3b67bf" }}>{userLocation[key]}</td>
                          </tr>
                        ))}
                    </table>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box sx={{ display: "flex", justifyContent: "start", gap: "15px", alignItems: "center" }}>
            <Typography variant="body1">Posts: {profile?.profile?.posts || 0}</Typography>
            <Typography variant="body1">Followers: {profile?.profile?.followers || 0}</Typography>
            <Typography variant="body1">Following: {profile?.profile?.following || 0}</Typography>
          </Box>
          <Box>
            <table style={{ borderSpacing: "10px", width: "100%" }}>
              <tr style={{ fontSize: "18px" }}>
                <td style={{ textTransform: "capitalize" }}>Wallet</td>
                <td style={{ color: "#3b67bf" }}>{wallet?.toFixed(2) || 0} AED</td>
              </tr>
              <tr style={{ fontSize: "18px" }}>
                <td style={{ textTransform: "capitalize" }}>Points</td>
                <td style={{ color: "#3b67bf" }}>{points?.points || 0}</td>
              </tr>
              <tr style={{ fontSize: "18px" }}>
                <td style={{ textTransform: "capitalize" }}>Points in AED</td>
                <td style={{ color: "#3b67bf" }}>{points?.amount?.toFixed(2) || 0} AED</td>
              </tr>
            </table>
          </Box>
          <Box py={2} sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Button
              onClick={() => {
                setOpenPoints(true);
              }}
              sx={{ color: "white !important" }}
              variant="contained"
            >
              Add Points
            </Button>
            <Button
              onClick={() => {
                setOpenWallet(true);
              }}
              sx={{ color: "white !important" }}
              variant="contained"
            >
              Add Wallet
            </Button>
          </Box>
          {walletTransactions?.length > 0 && (
            <Box mt={2} sx={{}}>
              <Box
                px={1}
                mb={1}
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography variant="body1">Last 5 Wallet Transactions</Typography>
                <Link
                  to={`/wallet-transactions?crm_uuid=${profile?.uuid}`}
                  style={{ textDecoration: "none" }}
                >
                  Show all
                </Link>
              </Box>
              {walletTransactions?.map((transaction, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    background: "#fff",
                    p: 1,
                    borderRadius: "8px",
                    m: 1,
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Chip
                    color={transaction?.transaction_type === "CREDIT" ? "success" : "error"}
                    label={transaction?.transaction_type}
                    size="small"
                    sx={{ fontSize: "12px" }}
                  ></Chip>
                  <Typography variant="body2">{transaction?.transaction_name}</Typography>
                  <Typography variant="body2">{transaction?.original_amount} AED</Typography>
                </Box>
              ))}
            </Box>
          )}

          {pointsTransactions?.length > 0 && (
            <Box mt={2} sx={{}}>
              <Box
                px={1}
                mb={1}
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography variant="body1">Last 5 Points Transactions</Typography>
                <Link
                  to={`/points-transactions?crm_uuid=${profile?.uuid}`}
                  style={{ textDecoration: "none" }}
                >
                  Show all
                </Link>
              </Box>
              {pointsTransactions?.map((transaction, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "20px",
                    background: "#fff",
                    p: 1,
                    borderRadius: "8px",
                    m: 1,
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <Chip
                    color={transaction?.transaction_type === "CREDIT" ? "success" : "error"}
                    label={transaction?.transaction_type}
                    size="small"
                    sx={{ fontSize: "12px" }}
                  ></Chip>
                  <Typography variant="body2">{transaction?.transaction_name}</Typography>
                  <Typography variant="body2">{transaction?.amount} pts</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>

      <Modal
        open={openWallet}
        onClose={() => setOpenWallet(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddWalletForm handleAddWalletTransaction={handleAddWalletTransaction} />
        </Box>
      </Modal>

      <Modal
        open={openPoints}
        onClose={() => setOpenPoints(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddPointsForm handleAddPointTransaction={handleAddPointTransaction} />
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Edit;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
